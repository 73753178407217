<template>
  <div class="index">
    <div class="hero">
      <img src="@/assets/images/hero.jpg">
    </div>
    <div class="features">
      <div v-for="feature in features" v-bind:key="feature.header">
        <h2>{{ feature.header }}</h2>
        <p>{{ feature.text }}</p>
      </div>
    </div>
    <div class="demo-wrapper">
      <div class="demo">
        <h2>Demo</h2>
        <PhrasesList :isDemo="true"></PhrasesList>
        <p>
          The demo consist of 3 Thai sentences. Unlock all sentences with our monthly subscription.
        </p>
        <p>
          Try searching for "bus station" to see how sentences are tagged to appear when searching for their synonyms. Tagged searches relies on exact match, meaning you're more likely to get a result when searching for an individual word.
        </p>
      </div>
    </div>
    <div class="languages">
      <h2>Languages</h2>
      <div class="list">
        <router-link v-for="language in languages" v-bind:key="language.id" :to="'/' + language.id" :class="language.id">
          <h3>{{ language.title }}</h3>
          <p>
            {{ language.text}}
          </p>
          <div class="visit">
            View {{ language.title }} collection
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import PhrasesList from '@/components/PhrasesList'
export default {
  data() {
    return {
      features: [
        { header: "👩‍🏫 Communicate like a local", text: "You will instantly be able to communicate your desired sentences even if you don't know the language." },
        { header: "🔥 Thousands of sentences", text: "Each sentence is expressed using the same phrases a local would use." },
        { header: "🔍 Advanced search", text: "Each phrase is carefully tagged, allowing you to easily discover the phrase you wish to express." },
        { header: "🏖️ Localized sentences", text: "The sentences are relevant to the language's country. For instance, Thai sentences include attractions in Thailand." },
        { header: "🌱 Growing collection", text: "We will continue to add new sentences to our collection." },
        { header: "🤗 Accepting requests", text: "Customers can submit sentences they would like for us to add to the collection." },
        { header: "📱 Responsive layout", text: "The website is carefully designed to work well on both mobile and desktop." },
        { header: "⭐ Highlight sentences", text: "Add your favorite sentences to your own collection." },
      ],
      languages: [
        { id: "thai", title: "Thai", text: "Our collection of Thai sentences are tailored towards life in Thailand. Each sentence include both Thai symbols, transcript and audio from our local teachers."}
      ]
    };
  },
  components: {
    PhrasesList,
  },
  mounted() {
    document.title = "Communicate like a local - PocketLang.com"
  },
}
</script>

<style lang="scss" scoped>
.index {
  .hero, .features, .languages, .demo {
    margin: .5rem 4rem;
  }
  .hero {
    img {
      max-width: 100%;
    }
    display: grid;
		justify-content: center;
    margin-bottom: 1rem;
  }
  .features {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(350px,1fr));
    gap: 0rem 1rem;
  }
  .demo-wrapper {
    background-color: #e3f6f5;
    padding: .5rem 0;
  }
  .languages {
    .list {
      display: grid;
      grid-template-columns: repeat(auto-fill,minmax(500px,1fr));
      grid-gap: 1rem;
      a {
        color: inherit;
        text-decoration: none;
      }
      .visit {
        margin-top: 1rem;
        text-align: right;
        color: #fff;
        text-decoration: underline;
      }
      .thai {
        padding: .5rem;
        background-color: #483D8B;
        p {
          font-weight: 300;
          margin: 0;
        }
        h3, p {
          color: #fff;
        }
      }
    }
  }
  @media(max-width:80rem) {
    .hero, .features, .languages, .demo {
      margin: 1rem 2rem;
    }
  }
  @media(max-width:60rem) {
    .hero, .features, .languages, .demo {
      margin: 1rem;
    }
    .languages .list {
      grid-template-columns: 1fr;
    }
  }
  @media(max-width:50rem) {
    .features {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media(max-width:37rem) {
    .features {
      grid-template-columns: 1fr;
    }
  }
}
</style>